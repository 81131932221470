import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./cart.css"
import { Row, Col, Container, Button } from 'react-bootstrap';



function Cart(props){
    const [ingredients, setIngredients] = useState({});
    const [count, setCount] = useState(0);
    let id;
    let recipes;
    console.log(count)
    const getId = () => {
        let temp = window.location.pathname.slice(1);
        id = temp.replace('%20',' '); 
    }
    const findRecipe = (array) => {
        getId();
        if(id && id !== 'about'){
            for(let i = 0; i < array.length; i++){
                if(array[i].title === id){
                    recipes = array[i]
                }
            }
        }
    }
    const merge = (...argument) => {
        let target = {};
        const merger = (obj) => {
            for (let prop in obj) {
                if (!target.hasOwnProperty(prop)) {
                    target[prop] = obj[prop];
                }
                else{
                    target[prop] = Number(obj[prop]) + Number(ingredients[prop])
                }
            }
        };
        for (let i = 0; i < argument.length; i++) {
            merger(argument[i]);
        }
        return target;
    };
    

    const addItem = () => {
        setCount(()=>count + 1)
        findRecipe(props.rec);
        if(id && id !== 'about' ){
            setIngredients(merge(ingredients, recipes.ingredients))
        }
    }
    const removeItem = () => {
        if(count > 0){
            setCount(()=>count - 1)
            findRecipe(props.rec);
            if(id && id !== 'about'){
                let newIngreds = {};
                Object.entries(recipes.ingredients).map(i => {
                    let ingred = i[0];
                    if(ingredients[ingred] > 0){
                    newIngreds[i[0]] =  -1 * i[1]
                    }
                });
                setIngredients(merge(ingredients, newIngreds));
            }
        }
        
    }
    return(
            <Container className = "body" fluid>
                <Row>
                    <Col className = 'mt-3'>
                        <h3 className = 'text-center'>Your Cart</h3>
                    </Col>
                </Row>
                <Row className = 'text-center'>
                    <Col>
                        <Button onClick = {()=>{addItem()}}>Add 1 to Cart</Button>
                    </Col>
                    <Col>
                        <Button onClick = {()=>{removeItem()}}>Remove Item</Button>
                    </Col>
                    <Col>
                        <Button onClick = {()=>{
                            setCount(0);
                            setIngredients({})
                            }}>Empty Cart</Button>
                    </Col>
                </Row>
                <Row className = 'mt-3'>
                    <ul>
                        {ingredients ? Object.entries(ingredients).map(ingredient => (
                            ingredient[1] > 0 ?
                        <li key = {ingredient[0]}>{+(ingredient[1])} {ingredient[0]}</li> : console.log()
                        )) : <p>loading...</p>}
                    </ul>
                </Row>
            </Container>
    )
}

export default Cart;
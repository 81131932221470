import React, {useState, useEffect} from 'react';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from 'axios';
import './App.css';
import About from './Components/about.js'
import Header from './Components/header.js';
import Cart from './Components/cart.js';
import Home from './Components/home.js';
import Recipe from './Components/recipe.js';


function App(){
    const [recipes, setRecipes] = useState([]);
    const url = 'https://h4ichefwebsite.herokuapp.com/api/recipe/'

    useEffect(() => {
        const fetchData = async() =>{
        const result = await axios(url);
        setRecipes(result.data);
      }
      fetchData();
    },[]);


    return(
        <body>
            <Router>
                <Header/>
                <Row>
                    <Col>
                        <Switch>
                            <Route path='/about' component={About}/>
                            <Route exact path="/" render={(props) => <Home {...props} rec={recipes}/>} />
                            <Route path='/:id' render={(props) => <Recipe {...props} rec={recipes}/>} />
                        </Switch>
                    </Col> 
                    <Col xs={4}>
                        <Switch>
                            <Cart rec={recipes}/>
                        </Switch>
                    </Col>
                </Row>
            </Router>
        </body>
    )
}

export default App;
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Button} from 'react-bootstrap';  
import axios from 'axios';
import "./recipe.css";

function Recipe(props){
    const [recipe, setRecipe] = useState({});
    const [servings, setServings] = useState(1);
    const [rate, setRate] = useState(0);
    const [click, setClick] = useState(false)
    useEffect(()=>{
        const url = 'https://h4ichefwebsite.herokuapp.com/api/recipe/' + window.location.pathname.slice(1);
        const fetchData = async() => {
            const response = await axios.get(url);
            setRecipe(response.data[0]);
        }
        fetchData();
    },[click])
    const getAvg = (array) => {
        let sum = 0
        for(let i = 0; i < array.length; i++){
            sum += +(array[i])
        }
        let a = +(Math.floor((sum/array.length*10))/10)
        return a
    }
    const buttonClick = async() =>{
        await axios.post('https://h4ichefwebsite.herokuapp.com/api/rating', {title:recipe.title,rating:rate})
        setClick(!click);
    }
    return(
        <Container className = "recipecontainer" fluid>
            <Row>
                <Col xs={6}>
                    <h1 className = 'mt-3 ml-5'>{recipe.title}</h1>
                </Col>
                <Col xs={5}className = 'mt-3 text-right'>
                    <h3 className = 'display-5'>Rating: {recipe.ratings ? getAvg(recipe.ratings) : <p>Loading...</p>}/5</h3>
                </Col>
                
            </Row>
            <Row className = 'mt-3 ml-5'>
                <Col xs={6}>
                    <Row>
                        <p>{recipe.desc}</p>
                    </Row>
                    <Row>
                        <b>Servings</b>
                    </Row>
                    <Row className = 'mt-3'>
                        <Col xs={1} className = 'p-0'>
                            <Button onClick = {()=>{servings > 0 ? setServings(servings - 1) : console.log("too low")}}>-</Button>
                        </Col>
                        <Col xs={1} className = 'p-0'>
                            <p className = 'text-center'>{servings}</p>
                        </Col>
                        <Col xs={1} className = 'p-0'>
                            <Button onClick = {()=>{setServings(servings + 1)}}>+</Button>
                        </Col>
                    </Row>
                    <Row className = 'mt-3'>
                        <b>Rate this recipe!(0-5)</b>
                    </Row>
                    <Row>
                        <Col xs={1} className = ' p-0 mt-3'>
                            <input type="number" name="quantity" min="1" max="5" onChange={event => setRate(event.target.value)}></input>
                        </Col>
                        <Col className = 'mt-3'>
                            <Button className='btn-sm' onClick = {()=>{if(rate<=5){buttonClick()}}}>Submit!</Button>
                        </Col>
                    </Row>
                    <Row className = 'mt-5'>
                        <h3>Ingredients!</h3>
                    </Row>
                </Col>
                <Col xs={6} className = '' >
                    <img src={recipe.picture} alt = '' height='300' width = '400' className = 'ml-3'></img>
                </Col>
            </Row>
            <Row className = 'ml-5'>
                <ul>
                    {recipe.ingredients ? Object.entries(recipe.ingredients).map(ingredient => (
                    <li key = {ingredient[0]}>{+(ingredient[1]*servings)} {ingredient[0]}</li> 
                    )) : <p>Loading...</p>}
                </ul>
            </Row>
            <Row className = 'ml-5 mt-1'>
                <h3>Instructions!</h3>
            </Row>
            <Row className = 'ml-5 mt-1'>
                <ol>
                    {recipe.instructions ? recipe.instructions.map(i => <li key={i}>{i}</li>) : <p>Loading...</p>}
                </ol>
            </Row>
        </Container>
    )
}

export default Recipe;
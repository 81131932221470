import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";


function Header(){
    return(
        <Container fluid className='p-0'>
            <Row className="bg-dark p-2 navbar">
                <Col>
                    <h3 className = " text-white text-left">Archie's Recipe Website</h3>
                </Col>
                <Col xs={1.7}>
                    <nav className = "navbar text-white">
                        <Link className = "h2 text-white" to='/'>Home</Link>
                    </nav>
                </Col>
            </Row>
        </Container>
    )
}

export default Header;